import React, {useState, useEffect, useRef} from 'react'
import {useCookies} from 'react-cookie'
import {Link} from 'gatsby'
import LinkIcon from '../../../lib/LinkIcon'

import styles from './business.module.css'

// nightlifeCategories,
export default function Business ({data, diningCategories, entertainmentAndRecreationCategories, retailCategories, serviceCategories, lodgingAndEventCentersCategories}) {
  // console.log({data})

  // Set State
  const [searchTitle, setSearchTitle] = useState('')
  const [businessData, setBusinessData] = useState(data)
  const [diningCategory, setDiningCategory] = useState('')

  const [entertainmentAndRecreationCategory, setEntertainmentAndRecreationCategory] = useState('')
  const [retailCategory, setretailCategory] = useState('')
  const [serviceCategory, setServiceCategory] = useState('')
  // const [nightlifeCategory, setNightlifeCategory] = useState('')
  const [lodgingAndEventCentersCategory, setLodgingAndEventCentersCategory] = useState('')
  const [searched, setSearched] = useState(false)

  // TODO: COOKIE
  const [cookies, setCookie, removeCookie] = useCookies([
    'DiningDirectoryBiz',
    'RetailDirectoryBiz',
    'EntAndRecDirectoryBiz',
    'ServiceDirectoryBiz',
    // 'NightlifeDirectoryBiz',
    'CentersDirectoryBiz',
    'BizSearch', 'BizDate'
  ])
  const maxAge = 3600 // 30 (2592000) days

  const messagesEndRef = useRef(null)

  const handleDiningCategoriesChange = e => {
    setDiningCategory(e.target.value)
    if (e.target.value) {
      setCookie('DiningDirectoryBiz', e.target.value, {path: '/', maxAge})
      setCookie('BizDate', Date.now(), {path: '/', maxAge})
    } else {
      removeCookie('DiningDirectoryBiz', {path: '/'})
    }
  }
  const handleEntertainmentAndRecreationCategoriesChange = e => {
    setEntertainmentAndRecreationCategory(e.target.value)
    if (e.target.value) {
      setCookie('EntAndRecDirectoryBiz', e.target.value, {path: '/', maxAge})
      setCookie('BizDate', Date.now(), {path: '/', maxAge})
    } else {
      removeCookie('EntAndRecDirectoryBiz', {path: '/'})
    }
  }
  const handleRetailCategoriesChange = e => {
    setretailCategory(e.target.value)
    if (e.target.value) {
      setCookie('RetailDirectoryBiz', e.target.value, {path: '/', maxAge})
      setCookie('BizDate', Date.now(), {path: '/', maxAge})
    } else {
      removeCookie('RetailDirectoryBiz', {path: '/'})
    }
  }
  const handleServiceCategoriesChange = e => {
    setServiceCategory(e.target.value)
    if (e.target.value) {
      setCookie('ServiceDirectoryBiz', e.target.value, {path: '/', maxAge})
      setCookie('BizDate', Date.now(), {path: '/', maxAge})
    } else {
      removeCookie('ServiceDirectoryBiz', {path: '/'})
    }
  }
  // const handleNightlifeCategoriesChange = e => {
  //   setNightlifeCategory(e.target.value)
  //   if (e.target.value) {
  //     setCookie('NightlifeDirectoryBiz', e.target.value, {path: '/', maxAge})
  //     setCookie('BizDate', Date.now(), {path: '/', maxAge})
  //   } else {
  //     removeCookie('NightlifeDirectoryBiz', {path: '/'})
  //   }
  // }
  const handleLodgingAndEventCentersCategoriesChange = e => {
    setLodgingAndEventCentersCategory(e.target.value)
    if (e.target.value) {
      setCookie('CentersDirectoryBiz', e.target.value, {path: '/', maxAge})
      setCookie('BizDate', Date.now(), {path: '/', maxAge})
    } else {
      removeCookie('CentersDirectoryBiz', {path: '/'})
    }
  }

  const handleTitleChange = e => {
    setSearchTitle(e.target.value)
    if (e.target.value) {
      setCookie('BizSearch', e.target.value, {path: '/', maxAge})
      setCookie('BizDate', Date.now(), {path: '/', maxAge})
    } else {
      removeCookie('BizSearch', {path: '/'})
    }
  }

  const handleReset = e => {
    e.preventDefault()

    // TODO: COOKIE
    removeCookie('DiningDirectoryBiz', {path: '/'})
    removeCookie('RetailDirectoryBiz', {path: '/'})
    removeCookie('EntAndRecDirectoryBiz', {path: '/'})
    removeCookie('ServiceDirectoryBiz', {path: '/'})
    // removeCookie('NightlifeDirectoryBiz', {path: '/'})
    removeCookie('CentersDirectoryBiz', {path: '/'})
    removeCookie('BizSearch', {path: '/'})
    removeCookie('BizDate', {path: '/'})

    setDiningCategory('')
    document.getElementById('diningCategories').selectedIndex = 0
    setEntertainmentAndRecreationCategory('')
    document.getElementById('entertainmentAndRecreationCategories').selectedIndex = 0
    setretailCategory('')
    document.getElementById('retailCategories').selectedIndex = 0
    setServiceCategory('')
    document.getElementById('serviceCategories').selectedIndex = 0
    // setNightlifeCategory('')
    // document.getElementById('nightlifeCategories').selectedIndex = 0
    setLodgingAndEventCentersCategory('')
    document.getElementById('lodgingAndEventCentersCategories').selectedIndex = 0
    setSearchTitle('')
    document.getElementById('title').value = ''
    setSearched(false)
    setBusinessData(data)
  }

  // Form Functions
  const handleSubmit = e => {
    e.preventDefault()

    const filteredData = data.filter(item => {
      let diningCategoryResults = item
      let entertainmentAndRecreationCategoryResults = item
      let retailCategoryResults = item
      let serviceCategoryResults = item
      // let nightlifeCategoryResults = item
      let lodgingAndEventCentersCategoryResults = item
      let titleResults = item
      // console.log({item})

      if (diningCategory) {
      //  console.log({diningCategory})
      //  console.log({item})
        diningCategoryResults =
        item.node._rawBusinessCategories.diningCategories && item.node._rawBusinessCategories.diningCategories.filter(
          ({_ref}) => _ref === diningCategory
        ).length > 0
      }
      if (entertainmentAndRecreationCategory) {
        entertainmentAndRecreationCategoryResults =
          item.node._rawBusinessCategories.entertainmentAndRecreationCategories && item.node._rawBusinessCategories.entertainmentAndRecreationCategories.filter(
            ({_ref}) => _ref === entertainmentAndRecreationCategory
          ).length > 0
      }
      if (retailCategory) {
        retailCategoryResults =
        item.node._rawBusinessCategories.retailCategories && item.node._rawBusinessCategories.retailCategories.filter(
          ({_ref}) => _ref === retailCategory
        ).length > 0
      }
      if (serviceCategory) {
        serviceCategoryResults =
        item.node._rawBusinessCategories.serviceCategories && item.node._rawBusinessCategories.serviceCategories.filter(
          ({_ref}) => _ref === serviceCategory
        ).length > 0
      }
      // if (nightlifeCategory) {
      //   nightlifeCategoryResults =
      //   item.node._rawBusinessCategories.nightlifeCategories && item.node._rawBusinessCategories.nightlifeCategories.filter(
      //     ({_ref}) => _ref === nightlifeCategory
      //   ).length > 0
      // }

      if (lodgingAndEventCentersCategory) {
        lodgingAndEventCentersCategoryResults =
        item.node._rawBusinessCategories.lodgingAndEventCentersCategories && item.node._rawBusinessCategories.lodgingAndEventCentersCategories.filter(
          ({_ref}) => _ref === lodgingAndEventCentersCategory
        ).length > 0
      }

      if (searchTitle) {
        titleResults =
          item.node.title
            .toLowerCase()
            .search(searchTitle.toLowerCase()) !== -1
      }
      setSearched(true)
      // nightlifeCategoryResults
      return titleResults && diningCategoryResults && entertainmentAndRecreationCategoryResults && retailCategoryResults && serviceCategoryResults && lodgingAndEventCentersCategoryResults
    })
    setBusinessData(filteredData)
    messagesEndRef.current.scrollIntoView({behavior: 'smooth'})
  }

  // TODO: Cookie
  const autoSubmit = () => {
    console.log('auto')
    cookies['RetailDirectoryBiz'] && setretailCategory(cookies['RetailDirectoryBiz'])
    cookies['DiningDirectoryBiz'] && setDiningCategory(cookies['DiningDirectoryBiz'])
    cookies['EntAndRecDirectoryBiz'] && setEntertainmentAndRecreationCategory(cookies['EntAndRecDirectoryBiz'])
    cookies['ServiceDirectoryBiz'] && setServiceCategory(cookies['ServiceDirectoryBiz'])
    // cookies['NightlifeDirectoryBiz'] && setNightlifeCategory(cookies['NightlifeDirectoryBiz'])
    cookies['CentersDirectoryBiz'] && setLodgingAndEventCentersCategory(cookies['CentersDirectoryBiz'])

    // cookies['NightlifeDirectoryBiz']
    if (cookies['RetailDirectoryBiz'] || cookies['DiningDirectoryBiz'] || cookies['EntAndRecDirectoryBiz'] || cookies['ServiceDirectoryBiz'] || cookies['CentersDirectoryBiz'] || cookies['BizSearch']) {
      const filteredData = data.filter(item => {
        let diningCategoryResults = item
        let entertainmentAndRecreationCategoryResults = item
        let retailCategoryResults = item
        let serviceCategoryResults = item
        // let nightlifeCategoryResults = item
        let lodgingAndEventCentersCategoryResults = item
        let titleResults = item
        // console.log({item})

        if (diningCategory) {
        //  console.log({diningCategory})
        //  console.log({item})
          diningCategoryResults =
          item.node._rawBusinessCategories.diningCategories && item.node._rawBusinessCategories.diningCategories.filter(
            ({_ref}) => _ref === diningCategory
          ).length > 0
        }
        if (entertainmentAndRecreationCategory) {
          entertainmentAndRecreationCategoryResults =
            item.node._rawBusinessCategories.entertainmentAndRecreationCategories && item.node._rawBusinessCategories.entertainmentAndRecreationCategories.filter(
              ({_ref}) => _ref === entertainmentAndRecreationCategory
            ).length > 0
        }
        if (retailCategory) {
          retailCategoryResults =
          item.node._rawBusinessCategories.retailCategories && item.node._rawBusinessCategories.retailCategories.filter(
            ({_ref}) => _ref === retailCategory
          ).length > 0
        }
        if (serviceCategory) {
          serviceCategoryResults =
          item.node._rawBusinessCategories.serviceCategories && item.node._rawBusinessCategories.serviceCategories.filter(
            ({_ref}) => _ref === serviceCategory
          ).length > 0
        }
        // if (nightlifeCategory) {
        //   nightlifeCategoryResults =
        //   item.node._rawBusinessCategories.nightlifeCategories && item.node._rawBusinessCategories.nightlifeCategories.filter(
        //     ({_ref}) => _ref === nightlifeCategory
        //   ).length > 0
        // }

        if (lodgingAndEventCentersCategory) {
          lodgingAndEventCentersCategoryResults =
          item.node._rawBusinessCategories.lodgingAndEventCentersCategories && item.node._rawBusinessCategories.lodgingAndEventCentersCategories.filter(
            ({_ref}) => _ref === lodgingAndEventCentersCategory
          ).length > 0
        }

        if (searchTitle) {
          titleResults =
            item.node.title
              .toLowerCase()
              .search(searchTitle.toLowerCase()) !== -1
        }
        setSearched(true)
        // nightlifeCategoryResults
        return titleResults && diningCategoryResults && entertainmentAndRecreationCategoryResults && retailCategoryResults && serviceCategoryResults && lodgingAndEventCentersCategoryResults
      })
      setBusinessData(filteredData)
      messagesEndRef.current.scrollIntoView({behavior: 'smooth'})
    } else {
      setretailCategory('')
      setDiningCategory('')
      setEntertainmentAndRecreationCategory('')
      setServiceCategory('')
      // setNightlifeCategory('')
      setLodgingAndEventCentersCategory('')
      setBusinessData(data)
    }
  }
  // || cookies['NightlifeDirectoryBiz']
  useEffect(() => {
    console.log('useEffect')
    if (cookies['RetailDirectoryBiz'] || cookies['DiningDirectoryBiz'] || cookies['EntAndRecDirectoryBiz'] || cookies['ServiceDirectoryBiz'] || cookies['CentersDirectoryBiz'] || cookies['BizSearch']) {
      cookies['RetailDirectoryBiz'] && setretailCategory(cookies['RetailDirectoryBiz'])
      cookies['DiningDirectoryBiz'] && setDiningCategory(cookies['DiningDirectoryBiz'])
      cookies['EntAndRecDirectoryBiz'] && setEntertainmentAndRecreationCategory(cookies['EntAndRecDirectoryBiz'])
      cookies['ServiceDirectoryBiz'] && setServiceCategory(cookies['ServiceDirectoryBiz'])
      // cookies['NightlifeDirectoryBiz'] && setNightlifeCategory(cookies['NightlifeDirectoryBiz'])
      cookies['CentersDirectoryBiz'] && setLodgingAndEventCentersCategory(cookies['CentersDirectoryBiz'])
      cookies['BizSearch'] && setSearchTitle(cookies['BizSearch'])
      autoSubmit()
    } else {
      setretailCategory('')
      setDiningCategory('')
      setEntertainmentAndRecreationCategory('')
      setServiceCategory('')
      // setNightlifeCategory('')
      setLodgingAndEventCentersCategory('')
      setSearchTitle('')
      autoSubmit()
    }
    // nightlifeCategory
  }, [retailCategory, diningCategory, entertainmentAndRecreationCategory, serviceCategory, lodgingAndEventCentersCategory, searchTitle])

  // console.log({businessData})
  // console.log({diningCategory})
  console.log(searched)
  return (
    <div>
      {/* Business Directory - FILTERS value={diningCategory || ''} */}
      <div className={styles.filterWrapper}>
        <form onSubmit={handleSubmit} className={styles.directoryForm4}>

          <div className={styles.fieldContainer}>
            <label
              className='sr-only'
              htmlFor='diningCategories'
            >Dining Categories</label>
            <select id='diningCategories' name='diningCategories' className={styles.customSelect} onChange={handleDiningCategoriesChange} value={diningCategory}
            >
              {/* <option defaultValue disabled value=''>Dining Categories</option> */}
              <option value='' defaultValue className={styles.customSelectDisabled}>Eat & Drink Categories</option>
              {diningCategories.map(cat => (
                <option value={cat.node._id} key={cat.node.id}>{cat.node.title}</option>
              ))}
            </select>
          </div>

          <div className={styles.fieldContainer}>
            <label
              className='sr-only'
              htmlFor='entertainmentAndRecreationCategories'
            >Entertainment And Recreation Categories</label>
            <select id='entertainmentAndRecreationCategories' name='entertainmentAndRecreationCategories' className={styles.customSelect} onChange={handleEntertainmentAndRecreationCategoriesChange} value={entertainmentAndRecreationCategory}>
              <option value='' defaultValue>Entertainment &amp; Recreation Categories </option>
              {entertainmentAndRecreationCategories.map(cat => (
                <option value={cat.node._id} key={cat.node.id}>{cat.node.title}</option>
              ))}
            </select>
          </div>

          <div className={styles.fieldContainer}>
            <label
              className='sr-only'
              htmlFor='retailCategories'
            >Retail Categories</label>
            <select id='retailCategories' name='retailCategories' className={styles.customSelect} onChange={handleRetailCategoriesChange} value={retailCategory}>
              <option value='' defaultValue>Retail Categories</option>
              {retailCategories.map(cat => (
                <option value={cat.node._id} key={cat.node.id}>{cat.node.title}</option>
              ))}
            </select>
          </div>

          <div className={styles.fieldContainer}>
            <label
              className='sr-only'
              htmlFor='serviceCategories'
            >Service Categories</label>
            <select id='serviceCategories' name='serviceCategories' className={styles.customSelect} onChange={handleServiceCategoriesChange} value={serviceCategory}>
              <option value='' defaultValue>Service Categories</option>
              {serviceCategories.map(cat => (
                <option value={cat.node._id} key={cat.node.id}>{cat.node.title}</option>
              ))}
            </select>
          </div>

          {/*  <div className={styles.fieldContainer}>
            <label
              className='sr-only'
              htmlFor='nightlifeCategories'
            >Nightlife Categories</label>
            <select id='nightlifeCategories' name='nightlifeCategories' className={styles.customSelect} onChange={handleNightlifeCategoriesChange} value={nightlifeCategory}>
              <option value='' defaultValue>Nightlife Categories</option>
              {nightlifeCategories.map(cat => (
                <option value={cat.node._id} key={cat.node.id}>{cat.node.title}</option>
              ))}
            </select>
          </div>
          */}

          <div className={styles.fieldContainer}>
            <label
              className='sr-only'
              htmlFor='lodgingAndEventCentersCategories'
            >Lodging &amp; Event Centers Categories</label>
            <select id='lodgingAndEventCentersCategories' name='lodgingAndEventCentersCategories' className={styles.customSelect} onChange={handleLodgingAndEventCentersCategoriesChange}
              value={lodgingAndEventCentersCategory}>
              <option value='' defaultValue>Lodging &amp; Event Centers Categories</option>
              {lodgingAndEventCentersCategories.map(cat => (
                <option value={cat.node._id} key={cat.node.id}>{cat.node.title}</option>
              ))}
            </select>
          </div>

          <div className={styles.fieldContainer}>
            <label
              className='sr-only'
              htmlFor='title'
            >By Title</label>
            <input
              onChange={handleTitleChange}
              id='title'
              name='title'
              type='text'
              placeholder='Search Businesses'
              className={styles.customInput}
              value={searchTitle}
            />
          </div>

          <div className={styles.buttonContainer} style={{display: 'flex'}}>
            <button
              className={styles.customButton}
              onClick={handleSubmit}
              name='submit'
            >Search
            </button>
            <button
              className={styles.customButtonReset}
              onClick={handleReset}
              name='reset'
              type='reset'
            >Reset
            </button>

          </div>

        </form>
      </div>
      {/* Business Directory - VIEW  */}
      <div aria-live='polite' ref={messagesEndRef} className={businessData.length > 0 && searched === true ? (styles.directoryTeaserWrapperSideBar) : undefined}>
        {businessData.length <= 0 && searched === true ? (<div className={styles.noResults}>There are no results for this search. Please click the RESET button for a complete list of results.</div>) : (<></>)}
        {searched ? (
          businessData.map((biz, i) => (
            <address key={i}>
              <div className='vcard'>

                <div className='org'><h3 className={styles.directoryBizTitle}> <Link to={`/business-directory/${biz.node.slug.current}/`}>{biz.node.title}</Link></h3></div>

                <div className='adr'>
                  <a href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURI(biz.node.location.streetAddress)},${encodeURI(biz.node.location.city)},${encodeURI(biz.node.location.state)}`} target='_blank' rel='noopener noreferrer' title={`Directions to ${biz.node.title}`} className={styles.directoryAddress}>
                    <div className='street-address'>{biz.node.location.streetAddress}</div>
                    <span className='locality sr-only'>{biz.node.location.city}</span> <span className='region sr-only'>{biz.node.location.state}</span>{' '}
                    <span className='postal-code sr-only'>{biz.node.location.zip}</span>
                    <span className='country-name sr-only'>United States</span>
                  </a>
                </div>

                <div className={styles.mapDirections}><LinkIcon type='map' />
                  <a href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURI(biz.node.location.streetAddress)},${encodeURI(biz.node.location.city)},${encodeURI(biz.node.location.state)}`} target='_blank' rel='noopener noreferrer' title={`Directions to ${biz.node.title}`} className={styles.mapLink}>map &amp; directions</a>
                </div>

                {biz.node.businessContact && biz.node.businessContact.contactPhone && (
                  <div className='tel' title={`Call ${biz.node.title}`}>
                    <LinkIcon type='call' />
                    <a href={`tel:${biz.node.businessContact.contactPhone}`} className={styles.callLink}>{biz.node.businessContact.contactPhone}</a>
                  </div>
                )}

                {/* <div><Link to={`/${biz.node.slug.current}/`}>More Info</Link></div> */}

              </div>
            </address>

          ))
        ) : (
          <>
            {/* <div className={styles.searchedNo}>Use the filters and/or search above to find the business(es) you’re look looking for.</div> */}
          </>
        )}
      </div>
    </div>
  )
}
